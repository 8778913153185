.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

/*.modal {*/
/*  border-radius: 5px;*/
/*  padding: 2rem;*/
/*  background-color: white;*/
/*  width: 30em;*/
/*}*/

.btn-submit {
  background-color: #42a942;
}

.btn-cancel {
  background-color: #d9534f;
}

.close {
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
}

.modal-content {
  margin-bottom: 2rem;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly;
}
